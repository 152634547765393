import React from "react";
import "../App.css";
import sprite2 from "../assets/svg/sprite.svg";
import { Link } from "react-router-dom";


function Softwareinc() {
  return (
    <div>            
            <div className="">
              <div
                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-4"
              >
                <div className="card card-teaser card-bg-primary rounded">
                  <div className="avatar size-lg mr-3">
                      <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/sire" className="text-white">Sire</Link>
                    </h5>
                    <p className="card-text text-sans-serif">
                    Applicativo per Valutazioni Tecnico-Economiche sulle Possibilità di Interventi di Riqualificazione Energetica del Patrimonio Edilizio degli Enti Locali
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-warning rounded">
                  <div className="avatar size-lg mr-3">
                    <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                   </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/victoria" className="text-white">VICTORIA</Link>                   
                    </h5>
                    <p className="card-text text-sans-serif">
                    Applicativo per la Valutazione degli Interventi incentivabili dal Conto TermicO e relativi Risparmi-Interfaccia APE
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-dark rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/docet" className="text-white">DOCET</Link>       
                    </h5>
                    <p className="card-text text-sans-serif">
                    L’applicativo per la certificazione energetica edifici residenziali esistenti con superficie utile inferiore o uguale a 200 m2
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-success rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/safeschool" className="text-white">Safeschool 4.0</Link>       
                    </h5>
                    <p className="card-text text-sans-serif">
                    L’applicativo per misurare i consumi energetici e le caratteristiche strutturali delle scuole
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-4"
              >
                <div className="card card-teaser card-bg-danger rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/shadowindow" className="text-white">Shadowindow</Link>       
                    </h5>
                    <p className="card-text text-sans-serif">
                    Software per il calcolo del risparmio annuo di energia con l’installazione di una schermatura solare a protezione di una superficie vetrata
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-secondary rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/test4ecasa" className="text-white">Test4ECasa</Link>    
                    </h5>
                    <p className="card-text text-sans-serif">
                    Il test che suggerisce come  rendere la propria abitazione più efficiente nel rispetto dell’ambiente
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-dark rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/condomini" className="text-white">Condomini+4.0</Link>    
                    </h5>
                    <p className="card-text text-sans-serif">
                    L’applicativo per l’analisi della vulnerabilità energetica-strutturale degli edifici condominiali
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-primary rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                        <Link to="/harp" className="text-white">HARPa</Link>
                    </h5>
                    <p className="card-text text-sans-serif">
                    L'applicativo per stimare la classe energetica dei generatori di calore obsoleti
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-4 justify-content-center"
              >
                <div className="card card-teaser card-bg-warning rounded mr-5">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/odesse" className="text-white">Odesse</Link>   
                    </h5>
                    <p className="card-text text-sans-serif">
                    Optimal design for smart energy
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-success rounded">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/abimviewer" className="text-white">ABIM Viewer</Link>   
                    </h5>
                    <p className="card-text text-sans-serif">
                    Il software per integrare i risultati delle diagnosi energetiche all’interno del modello BIM dell’edificio.
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-success rounded d-none">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/abimviewer" className="text-white">ABIM Viewer</Link>   
                    </h5>
                    <p className="card-text text-sans-serif">
                    Il software per integrare i risultati delle diagnosi energetiche all’interno del modello BIM dell’edificio.
                    </p>
                  </div>
                </div>
                <div className="card card-teaser card-bg-success rounded d-none">
                  <div className="avatar size-lg mr-3">
                  <svg className="icon">
                      <use href={ sprite2 + "#it-download" }></use>
                      </svg>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title pb-3">
                    <Link to="/abimviewer" className="text-white">ABIM Viewer</Link>   
                    </h5>
                    <p className="card-text text-sans-serif">
                    Il software per integrare i risultati delle diagnosi energetiche all’interno del modello BIM dell’edificio.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
  );
};

export default Softwareinc;