import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem, Icon } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Detrazionifiscali() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Detrazioni fiscali
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Detrazioni fiscali</h1>
                            <div className="row">
                                <div className="col-md-9">
                                Interventi di riqualificazione energetica e di ristrutturazione del patrimonio edilizio esistente in Italia. 
                                </div>
                                <div className="col-md-3">
                                <Link className="nav-link text-right" to="statistiche"><span class="btn btn-primary text-white">Dati Nazionali</span></Link>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#bonuscasa"
                                                    ><span>Bonus Casa</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#ecobonus"
                                                    ><span>Ecobonus</span></a
                                                >
                                                </li>                                                
                                                <li className="nav-item">
                                                <a className="nav-link" href="#superecobonus"
                                                    ><span>SuperEcobonus</span></a
                                                >
                                                </li>                                               
                                                <li className="nav-item ">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="bonuscasa" className="it-page-section anchor-offset">
                                    <h4>Bonus Casa</h4>
                                    <p className="text-serif">
                                    In analogia a quanto già previsto in materia di detrazioni fiscali per la riqualificazione energetica degli edifici, anche per il Bonus Casa per i lavori terminati dal 2018 in poi, occorre trasmettere per via telematica all’ENEA le Schede Descrittive degli interventi che comportano risparmio energetico e/o utilizzo delle fonti energetiche rinnovabili. 
                                    Per l’elenco degli interventi soggetti all’obbligo di invio a Enea, si rimanda alla <a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/bonus-casa.html" target="_blank" rel="noreferrer">pagina dedicata</a><br/><br/>
                                    <LinkList>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/bonus-casa/faq-bonus-casa.html" target="_blank">
                                                <span><Icon icon="it-clip" /> FAQ </span>
                                            </LinkListItem>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/bonus-casa/bonus-casa-guida-rapida-enea.html" target="_blank">
                                                <span><Icon icon="it-clip" /> Guida Rapida alla compilazione  </span>
                                            </LinkListItem>
                                        </LinkList>
                                        <a href="https://detrazionifiscali.enea.it/ecobonus.asp" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Vai al portale</span></span></a>
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="ecobonus" className="it-page-section anchor-offset">
                                    <h4>Ecobonus</h4>
                                    <p className="text-serif">
                                    Questa agevolazione fiscale comprende gli interventi di riqualificazione energetica degli edifici esistenti e che consente di beneficiare di un’aliquota di detrazione fiscale variabile a seconda della tipologia dell’intervento. 
                                    Per l’elenco degli interventi incentivabili e delle relative aliquote di detrazione, si rimanda alla <a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus.html" target="_blank" rel="noreferrer">pagina dedicata</a><br/><br/>
                                        <LinkList>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/faq-ecobonus.html" target="_blank">
                                                <span><Icon icon="it-clip" /> FAQ </span>
                                            </LinkListItem>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/vademecum.html" target="_blank">
                                                <span><Icon icon="it-clip" /> Vademecum </span>
                                            </LinkListItem>
                                        </LinkList>
                                        <a href="https://detrazionifiscali.enea.it/bonuscasa.asp" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Vai al portale</span></span></a>

                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="superecobonus" className="it-page-section anchor-offset">
                                    <h4>SuperEcobonus</h4>
                                    <p className="text-serif">
                                    Il “Decreto Rilancio ha introdotto l'aliquota di detrazione nella misura del 110% per i cosiddetti interventi “trainanti” e “trainati”, nel rispetto degli specifici requisiti richiesti e sempreché sia sempre conseguito un risparmio energetico. 
                                    Per ulteriori approfondimenti, si rimanda alla  <a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus.html" target="_blank" rel="noreferrer">pagina dedicata</a><br/><br/>
                                    <LinkList>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus.html" target="_blank">
                                                <span><Icon icon="it-clip" /> FAQ </span>
                                            </LinkListItem>
                                            <LinkListItem className="px-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus/approfondimenti.html" target="_blank">
                                                <span><Icon icon="it-clip" /> Approfondimenti  </span>
                                            </LinkListItem>
                                        </LinkList>
                                        <a href="https://detrazionifiscali.enea.it/superecobonus.asp" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Vai al portale</span></span></a>

                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                        <h5 className="mt-3">Efficienza energetica</h5>
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/documenti-di-riferimento/leggi-incentivanti.html" target="_blank" rel="noreferrer">Leggi incentivanti dal 2012 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/documenti-di-riferimento/normativa-sull-efficienza-energetica.html" target="_blank" rel="noreferrer">Principali normative </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/documenti-di-riferimento/documentii-agenzia-delle-entrate.html" target="_blank" rel="noreferrer">Documenti dell'agenzia delle entrate  </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                        <h5>Ecobonus</h5>
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/documenti-di-riferimento/normativa-ecobonus.html" target="_blank" rel="noreferrer">Normativa </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                        <h5>Superbonus 110% </h5>
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus/superbonus-documenti-di-riferimento.html" target="_blank" rel="noreferrer">Normativa</a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus/approfondimenti.html" target="_blank" rel="noreferrer">Approfondimenti</a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus/note-di-chiarimento-enea.html?layout=edit" target="_blank" rel="noreferrer">Note di chiarimento ENEA</a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/documenti-di-riferimento/documentii-agenzia-delle-entrate.html" target="_blank" rel="noreferrer">Agenzia delle entrate</a></span>
                                            </LinkListItem>
                                        </LinkList>
                                        </p>
                                     </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Detrazionifiscali;
