import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import Map from "../components/mappa.js";
import Softwareinc from "../components/softwareinc.js";
import sprite2 from "../assets/svg/sprite.svg";
import { Link } from "react-router-dom";
import { CardBody, CardTitle, CardText, CardReadMore, Card, Icon, Container, Row, CardCategory, LinkList, LinkListItem } from 'design-react-kit';



function Home() {
  return (
  <div>

    <HeaderTop />
      <div className="container my-4">
        <section className="jumbotron text-center">
          <div className="row">
            <div className="col-md-6">
              <div className="container text-left">
                <h3 className="jumbotron-heading text-left">La prestazione energetica degli edifici in Italia</h3>
                <p className="lead text-muted text-left">Una panoramica sulle statistiche ENEA relative alla prestazione energetica degli edifici suddivise per anno</p>
                <p>
                  <Link className="btn btn-primary my-2" to="/statistiche" >Clicca qui!</Link>
                </p>
              </div>
            </div>
            <div className="col-md-6"><Map /></div>
          </div>
        </section>
      </div>
      <section id="evidenza">
        <div className="section section-background-header py-5">
          <div className="container">
            <div className="row text-center m-auto">
              <h4 className="servizi px-3 text-white">Servizi</h4>
            </div>
            <div className="py-4">
              <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3">
                <div className="card card-teaser no-after rounded shadow">
                    <div className="card-body pb-5">

                      <h5 className="card-title mb-3"> <svg className="icon">
                        <use href={ sprite2 + "#it-tool" }></use>
                        </svg>Ape</h5>
                      <p className="card-text">
                        L’Attestato di Prestazione Energetica è un documento che attesta la prestazione e la classe energetica di un immobile e indica gli interventi migliorativi più convenienti.
                      </p>
                        <LinkList className="mt-3">
                          <LinkListItem className="px-0" href="http://www.portale4e.it/gestionale/Materiale_Articoli/Cittadini/APE/APE%20-%20Vademecum%20per%20il%20cittadino%20(versione%20stampabile).pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Vademecum per il cittadino</span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="https://www.mise.gov.it/images/stories/normativa/DM_Linee_guida_APE.pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Linee guida nazionali</span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="https://www.mise.gov.it/images/stories/normativa/DM_Linee_guida_APE_appendiceA.pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Casi di esclusione dall’obbligo di dotazione APE</span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="https://siape.enea.it/" target="_blank">
                            <span><Icon icon="it-clip" /> SIAPE</span>
                          </LinkListItem>
                        </LinkList>

                    </div>
                    <Link className="read-more" to="/ape" >
                      <span className="text">Esplora argomento</span>
                      <svg className="icon">
                      <use href={ sprite2 + "#it-arrow-right" }></use>
                      </svg>
                    </Link>
                </div>
                <div className="card card-teaser no-after rounded shadow">
                    <div className="card-body pb-5">
                      <h5 className="card-title mb-3"> <svg className="icon">
                        <use href={ sprite2 + "#it-tool" }></use>
                        </svg>Detrazioni Fiscali</h5>
                      <p className="card-text">
                      Interventi di riqualificazione energetica e di ristrutturazione del patrimonio edilizio esistente in Italia.
                      </p>
                      <div className="link-list-wrapper">
                        <ul className="mt-3 link-list">
                            <li className="px-0 mt-4">
                            <div><Icon icon="it-clip" /> Bonus Casa: </div>
                            <div className="text-center" ><a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/bonus-casa/faq-bonus-casa.html" target="_blank">Faq</a> e <a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/bonus-casa/bonus-casa-guida-rapida-enea.html" target="_blank">Guida Rapida alla compilazione</a> </div>
                           </li>   
                           <li className="px-0 mt-3">
                            <div><Icon icon="it-clip" /> Ecobonus: </div>
                            <div className="text-center" ><a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/faq-ecobonus.html" target="_blank">Faq</a> e <a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/ecobonus/vademecum.html" target="_blank">Vademecum degli interventi </a> </div>
                           </li>  
                           <li className="px-0 mt-3">
                            <div><Icon icon="it-clip" /> Superbonus:</div>
                            <div className="ml-5" ><a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus.html" target="_blank">Faq</a> e <a rel="noreferrer" className="d-inline p-0" href="https://www.efficienzaenergetica.enea.it/detrazioni-fiscali/superbonus/approfondimenti.html" target="_blank">Approfondimenti </a> </div>
                           </li>
                        </ul>
                        </div>
                    </div>
                    <Link className="read-more" to="/detrazioni_fiscali" >
                      <span className="text">Esplora argomento</span>
                      <svg className="icon">
                      <use href={ sprite2 + "#it-arrow-right" }></use>
                      </svg>
                    </Link>
                </div>
                <div className="card card-teaser no-after rounded shadow">
                  <div className="card-body pb-5">

                    <h5 className="card-title mb-3"><svg className="icon">
                      <use href={ sprite2 + "#it-tool" }></use>
                      </svg> Diagnosi Energetiche</h5>
                    <p className="card-text">
                    Una metodologia comune e standardizzata ai fini del retrofit energetico di un edificio
                      </p>
                        <LinkList className="mt-3">
                          <LinkListItem className="px-0" href="https://store.uni.com/catalogo/uni-tr-11775-2020" target="_blank">
                            <span><Icon icon="it-clip" /> Linee guida per le diagnosi energetiche degli edifici - UNI/TR 11775:2020  </span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="/doc/PRESENTAZIONE_DE_EDIFICI.pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Linee guida per le diagnosi energetiche degli edifici </span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="/doc/SCHEDA_RILIEVO_SCUOLE.pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Scheda di rilievo scuole  </span>
                          </LinkListItem>
                          <LinkListItem className="px-0" href="/doc/SCHEDA_RILEVO_UFFICI.pdf" target="_blank">
                            <span><Icon icon="it-clip" /> Scheda di rilievo uffici  </span>
                          </LinkListItem>
                        </LinkList>

                  </div>
                  <Link className="read-more" to="/diagnosi_energetiche" >
                      <span className="text">Esplora argomento</span>
                      <svg className="icon">
                      <use href={ sprite2 + "#it-arrow-right" }></use>
                      </svg>
                    </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                <div className="row">
                  <div className="col text-center">
                    <Link to="/servizi" className="btn btn-primary mt-2 w-100">Altri servizi</Link>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
        <section id="calendario" className="mt-5">
          <div className="section section-muted pb-5 pt-0">
            <Container>
              <Row>
                <div className="card-wrapper card-teaser-wrapper card-overlapping card-teaser-wrapper-equal card-teaser-block-3">
                  <Card
                    className="card-teaser-image card-flex no-after rounded shadow"
                    noWrapper
                    teaser
                  >
                      <CardBody className="p-4">
                        <CardCategory>
                          <Icon icon="it-calendar" />
                          Notizie
                        </CardCategory>
                        <CardTitle className="font-weight-semibold">
                        Ministero della Transizione Ecologica: da Fondo Kyoto nuove agevolazioni per scuole, ospedali e impianti sportivi
                        </CardTitle>
                        <CardText className="card-text mb-5">
                        Oltre 187 milioni di euro per la messa in efficienza e il risparmio idrico di scuole, strutture sanitarie e impianti sportivi di proprietà pubblica.
                        </CardText>
                      </CardBody>
                    <Link to="/notizie">
                    <CardReadMore
                      className="pl-4 mt-5"
                      iconName="it-arrow-right"
                      text="Tutte le notizie"
                    />
                    </Link>
                  </Card>
                  <Card
                    className="no-after rounded shadow"
                    noWrapper
                    teaser
                  >
                    <CardBody className="pb-5">
                      <CardCategory>
                        <Icon icon="it-calendar" />
                        Eventi
                      </CardCategory>
                      <CardTitle className="">
                        <a href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo/save-the-date-24-03-2021-dalle-09-30-alle-11-30-webinar-efficientamento-energetico-degli-edifici-la-diagnosi-energetica-e-il-quadro-nazionale-delle-detrazioni-fiscali-il-progetto-europeo-efficient-buildings-e-i-risultati-del-progetto-teeschools.html?highlight=WyJlZGlmaWNpIl0" target="_blank" rel="noreferrer">Efficientamento energetico degli edifici: la diagnosi energetica e il quadro nazionale delle detrazioni fiscali. Il progetto europeo Efficient Buildings e i risultati del progetto TEESCHOOLS</a>
                      </CardTitle>
                    </CardBody>
                    <Link to="/eventi">
                    <CardReadMore
                      iconName="it-arrow-right"
                      text="Tutti gli eventi"
                    />
                    </Link>
                  </Card>
                  <Card
                    className="no-after rounded shadow"
                    noWrapper
                    teaser
                  >
                    <CardBody className="pb-5">
                      <CardCategory>
                        <Icon icon="it-bookmark" />
                        Rapporti annuali
                      </CardCategory>
                      <LinkList className="mt-4">
                        <LinkListItem
                          className="icon-left"
                        >
                          <Icon
                            color="primary"
                            icon="it-file"
                          />
                          <span className="m-0">
                          <a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-sulla-certificazione-energetica-degli-edifici/rapporto-annuale-sulla-certificazione-energetica-degli-edifici-2021.html" target="_blank" rel="noreferrer" className="pl-3"> Rapporto Annuale sulla Certificazione Energetica degli Edifici 2021</a>
                          </span>
                        </LinkListItem>
                        <LinkListItem className="icon-left">
                          <Icon
                            color="primary"
                            icon="it-file"
                          />
                          <span className="m-0">
                          <a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/raee-rapporto-annuale-sull-efficienza-energetica/rapporto-annuale-sull-efficienza-energetica-2022.html" target="_blank" rel="noreferrer" className="pl-3">Rapporto annuale sull’efficienze energetica 2021</a>
                          </span>
                        </LinkListItem>

                      </LinkList>
                    </CardBody>
                    <Link to="/pubblicazioni">
                    <CardReadMore
                      iconName="it-arrow-right"
                      text="Tutti i rapporti"
                    />
                    </Link>
                  </Card>
                </div>
              </Row>

            </Container>
          </div>
        </section>
        <section id="evidenza2">
          <div className="section py-5">
            <div className="row">
              <h3>Vetrina tecnologica</h3>
            </div>
            <Softwareinc />
          </div>
        </section>

        <FooterBottom />
        </div>
  );
};

export default Home;
