import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem, Icon } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Diagnosienergetiche() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Diagnosi Energetiche
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Diagnosi Energetiche</h1>
                            <div className="row">
                                La diagnosi energetica (DE) rappresenta una metodologia comune e standardizzata ai fini del retrofit energetico di un edificio. Essa attraverso l’individuazione delle caratteristiche di interazione fra gli elementi di involucro e di impianto (sistema edificio-impianto) consente  il raggiungimento dei seguenti obiettivi:
                                    <ul>
                                        <li>acquisizione del consumo energetico dell’edificio;</li>
                                        <li>individuazione degli interventi di miglioramento della prestazione energetica dell’edificio;</li>
                                        <li>valutazione dei benefici energetici, economici, ambientali e sociali degli interventi;</li>
                                        <li>condivisione dei risultati per effetto della redazione del rapporto di DE.</li>
                                    </ul>
                                    La DE si configura come uno strumento dalle alte potenzialità per l’analisi, in maniera standardizzata, dei consumi energetici del parco edilizio italiano, nonché l’identificazione e valutazione, energeticamente ed economicamente, dei potenziali interventi di miglioramento energetico.
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#documenti" rel="noreferrer"
                                                    ><span>Documenti</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#linee" rel="noreferrer"
                                                    ><span>Linee guida</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#pubblicazioni" rel="noreferrer"
                                                    ><span>Pubblicazioni</span></a
                                                >
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    La diagnosi energetica (DE) di un edificio è una procedura sistematica finalizzata ad ottenere un'adeguata conoscenza del profilo di consumo energetico di un edificio o gruppo di edifici, a individuare e quantificare le opportunità di risparmio energetico sotto il profilo costi - benefici e a riferire in merito ai risultati (fonte: Dlgs 146/2016).<br/>

                                    <b>La riqualificazione energetica degli edifici esistenti sta assumendo una connotazione sempre più centrale nelle politiche europee e nazionali, con l’obiettivo di raggiungere livelli energetici prestazionali del parco edilizio esistente sempre più elevati e performanti. </b> Si tratta di un obiettivo di particolare rilievo che deve essere raggiunto a partire dalla conoscenza dello stato reale del parco edilizio nazionale (chiamato convenzionalmente ‘stato di fatto’) nonché attraverso accurate analisi per identificare gli interventi più efficaci a livello tecnico ed economico.<br/>
                                    Quando si parla di retrofit energetico è ormai consuetudine associare interventi sull’involucro, quali ad esempio la realizzazione del cappotto termico sulle componenti opache dell’edificio per ridurne il fabbisogno di energia per il riscaldamento, e interventi sugli impianti, quali la sostituzione del generatore di calore obsoleto: in realtà, si tratta di una procedura piuttosto articolata e complessa che richiede delle conoscenze specifiche del sistema edificio-impianto. Valutando singolarmente gli interventi di efficienza energetica, sicuramente si otterrà un miglioramento delle prestazioni energetiche dell’edificio: quando invece si analizza un “pacchetto di interventi” da realizzare contemporaneamente, allora risulta necessaria un’analisi più dettagliata allo scopo di capire come interagiscono o si influenzano tra loro i vari interventi.<br/>
                                    <b>Di fatto, quindi, Il retrofit energetico di un edificio deve necessariamente passare per una conoscenza adeguata del sistema edificio-impianto che richiede non solo la conoscenza delle caratteristiche dell’involucro edilizio o delle componenti impiantistiche presenti, ma lo studio di come questi due sistemi interagiscono tra loro e quindi determinare con maggiore precisione il reale risparmio energetico ed economico che ne consegue.</b><br/>
                                    Enea e il gruppo di lavoro del CTI (Comitato Termotecnico Italiano) che si occupa dell’efficienza energetica e gestione dell'energia in ambito normativo, con lo scopo di fornire una metodologia comune e standardizzata, hanno messo a punto una  <b>procedura sistematica di raccolta e analisi dati applicabile per la redazione di una Diagnosi Energetica negli edifici </b>. Si tratta di una procedura di analisi coordinata finalizzata a determinare i reali consumi energetici del sistema edificio-impianto e ad individuare i possibili interventi di miglioramento energetico attraverso un’analisi tecnico-economica degli stessi.  <b>La procedura è dettagliata nel rapporto tecnico UNI/TR 11775:2020 che costituisce una linea guida Nazionale per l’applicazione della UNI CEI EN 16247-2 sulle diagnosi energetiche degli edifici. </b><br/>
                                    Il rapporto tecnico costituisce una guida per l’esecuzione delle diagnosi energetiche degli edifici (ad uso residenziale, terziario o altri assimilabili) e fornisce indicazioni e modalità operative per:
                                    <ul>
                                        <li>la raccolta e l’analisi delle spese energetiche;</li>
                                        <li>la raccolta e l’analisi della documentazione tecnica e la definizione dei controlli e delle verifiche edili e impiantistiche;</li>
                                        <li>la definizione dei fattori di aggiustamento dei consumi fatturati;</li>
                                        <li>l’analisi dei servizi energetici;</li>
                                        <li>la costruzione dell’inventario energetico;</li>
                                        <li>il calcolo degli indicatori di prestazione energetica;</li>
                                        <li>l’individuazione delle opportunità di miglioramento dell’efficienza energetica;</li>
                                        <li>l’analisi costi-benefici;</li>
                                        <li>la valutazione della priorità degli interventi.</li>
                                    </ul>
                                    La procedura fin qui sintetizzata è descritta dettagliatamente nel rapporto tecnico [UNI/TR 11775:2020], a cui si rimanda per maggiori approfondimenti.

                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                    La diagnosi energetica deve essere eseguita da un tecnico qualificato, come ad esempio un esperto in gestione dell'energia certificato secondo la norma UNI CEI 11339, seguendo le indicazioni della norma UNI CEI EN 16247-1:2012.
                                        <LinkList>
                                            <LinkListItem>
                                                <span>
                                                    <a href="http://store.uni.com/catalogo/uni-cei-en-16247-1-2012?josso_back_to=http://store.uni.com/josso-security-check.php&josso_cmd=login_optional&josso_partnerapp_host=store.uni.com" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> La norma UNI CEI EN 16247-1:2012: Requisiti generali </a>
                                                </span>
                                                <p>Applicazione a tutte le forme di aziende ed organizzazioni, a tutte le forme di energia e di utilizzo della stessa, con esclusione delle singole unità immobiliari residenziali. Definizione di requisiti, metodologia comune e  prodotti della Diagnosi energetica.</p>
                                            </LinkListItem>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://store.uni.com/catalogo/uni-cei-en-16247-2-2014" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> La norma UNI CEI EN 16247-1:2012: Edifici </a>
                                                </span>
                                                <p>Applicazione alle DE specifiche per gli edifici congiuntamente alla EN 16247-1:2012 che integra e rispetto alla quale fornisce ulteriori requisiti.<br/>
                                                    Definizione di requisiti, metodologia e reportistica della DE relativa all’edificio o al gruppo di edifici, esclusione delle singole residenze private.</p>
                                            </LinkListItem>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://store.uni.com/catalogo/uni-cei-en-16247-5-2015" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> UNI CEI EN 16247-5:2014: Competenze dell'auditor energetico </a>
                                                </span>
                                                <p>Definizione dei requisiti di competenza del Responsabile della DE (REDE).</p>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="documenti" className="it-page-section anchor-offset mt-5">
                                    <h4>Documenti di supporto</h4>
                                    <p className="text-serif">
                                        <LinkList>
                                            <LinkListItem className="px-0" href="/doc/PRESENTAZIONE_DE_EDIFICI.pdf" target="_blank">
                                                <span><Icon icon="it-clip" /> Linee guida per le diagnosi energetiche degli edifici </span>
                                            </LinkListItem>
                                            <LinkListItem className="px-0" href="/doc/SCHEDA_RILIEVO_SCUOLE.pdf" target="_blank">
                                                <span><Icon icon="it-clip" /> Scheda di rilievo scuole  </span>
                                            </LinkListItem>
                                            <LinkListItem className="px-0" href="/doc/SCHEDA_RILEVO_UFFICI.pdf" target="_blank">
                                                <span><Icon icon="it-clip" /> Scheda di rilievo uffici  </span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="linee" className="it-page-section anchor-offset mt-5">
                                    <h4>Linee guida</h4>
                                    <p className="text-serif">
                                    UNI/TR 11775:2020 “Diagnosi Energetiche - Linee guida per le diagnosi energetiche degli edifici”.<br/>
                                    Linea guida per l'applicazione della UNI CEI EN 16247-2:2014  ai fini della conduzione delle DE degli edifici ad uso residenziale, terziario o altri assimilabili.
                                    Indicazioni e modalità operative per:
                                    <ul>
                                        <li>raccolta e analisi delle spese energetiche;</li>
                                        <li>raccolta e analisi delle documentazioni tecniche disponibili e definizione dei controlli e delle verifiche edili e impiantistiche;</li>
                                        <li>definizione dei fattori di aggiustamento dei consumi fatturati;</li>
                                        <li>analisi dei servizi energetici;</li>
                                        <li>costruzione dell'inventario energetico;</li>
                                        <li>calcolo degli indicatori di prestazione energetica;</li>
                                        <li>individuazione di azioni di miglioramento dell'efficienza energetica;</li>
                                        <li>analisi costi-benefici;</li>
                                        <li>valutazione della priorità degli interventi.</li>
                                    </ul>
                                    <a href="https://store.uni.com/catalogo/uni-tr-11775-2020" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">UNI/TR 11775:2020</span></span></a><br/>
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="pubblicazioni" className="it-page-section anchor-offset mt-5">
                                    <h4>Pubblicazioni</h4>
                                    <p className="text-serif">
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-file" }></use>
                                            </svg>
                                            <span><a href="https://www.eai.enea.it/archivio/efficienza-energetica-avanti-tutta/le-linee-guida-nazionali-enea-e-cti-per-le-diagnosi-energetiche-degli-edifici.html" target="_blank" rel="noreferrer">Le linee guida nazionali ENEA e CTI per le diagnosi energetiche degli edifici </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Diagnosienergetiche;
