import {React, useState} from "react";
import sprite2 from "../assets/svg/sprite.svg";
import { Nav, Header, HeaderContent, HeaderToggler, Icon, Collapse, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, LinkList } from 'design-react-kit';
import { Link } from 'react-router-dom';

function LinkListItem({href, ...prop}) {
  return (
    <li><Link className="list-item" to={href} {...prop}/></li>
  )
}

function NavLink({href, ...prop}) {
  return (
    <li className="nav-item"><Link className="nav-link" to={href} {...prop}/></li>
  )
}

function Menu() {

  const [openNav, setOpenNav] = useState(false);
  const toggle = () => {
    setOpenNav(!openNav);
  };
  return (
    <Header
  theme=""
  type="navbar"
>
  <HeaderContent
    expand="lg"
    megamenu
  >
    <HeaderToggler
      aria-controls="nav1"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={() =>toggle()}
    >
      <Icon icon="it-burger" />
    </HeaderToggler>
    <Collapse
      header
      navbar
      onOverlayClick={() =>toggle()}
      isOpen={openNav}
    >
      <div className="menu-wrapper">
        <Nav navbar>
          <NavItem active>
            <NavLink
              active
              href="/"
            >
              <span>
                Home{' '}
              </span>
              <span className="sr-only">
                current
              </span>
            </NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Il Portale
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/progetto">
                     <span>Progetto</span>
                    </LinkListItem>
                    <LinkListItem to="/attivita">
                    <span>Attività</span>
                    </LinkListItem>
                    <LinkListItem to="/mappasito">
                    <span>Mappa del sito</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>
          <NavItem>
          <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Edifici
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/ed_caratteristiche">
                     <span>Caratteristiche Principali</span>
                    </LinkListItem>
                    <LinkListItem to="/nzeb">
                    <span>Edificio nZEB</span>
                    </LinkListItem>
                    <LinkListItem to="/normative">
                    <span>Normative</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>

          </NavItem>
          <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Formazione
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/formazione_professionale">
                   <span>Formazione Professionale</span>
                    </LinkListItem>
                    <LinkListItem to="/kcom">
                      <span>Piattaforma KCOM</span>
                    </LinkListItem>
                    <LinkListItem to="/elearn">
                     <span>Piattaforma di e-Learning</span>
                    </LinkListItem>
                    <LinkListItem to="/glossario">
                    <span>Glossario EE</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>
        </Nav>
        <Nav
          navbar
          secondary
        >
          <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                     <svg className="icon fill-white">
                        <use href={ sprite2 + "#it-tool" }></use>
                      </svg>Servizi
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/ape">
                    <span>Certificazione Energetica Edifici</span>
                    </LinkListItem>
                    <LinkListItem to="/detrazioni_fiscali">
                     <span>Detrazioni Fiscali</span>
                    </LinkListItem>
                    <LinkListItem to="/diagnosi_energetiche">
                     <span>Diagnosi Energetiche</span>
                    </LinkListItem>
                    <LinkListItem to="/conto_termico">
                     <span>Conto Termico</span>
                    </LinkListItem>
                    <LinkListItem to="/certificati_bianchi">
                     <span>Certificati bianchi</span>
                    </LinkListItem>
                    <LinkListItem to="/fondo_nazionale">
                    <span>Fondo Nazionale EE</span>
                    </LinkListItem>
                    <LinkListItem to="/prepac">
                   <span>PREPAC</span>
                    </LinkListItem>
                    <LinkListItem to="/epc">
                   <span>EPC</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                            <svg className="icon fill-white">
                                  <use href={ sprite2 + "#it-chart-line" }></use>
                                </svg>Statistiche
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/banche_dati">
                    <span>Banche Dati</span>
                    </LinkListItem>
                    <LinkListItem to="/statistiche">
                      <span>Statistiche Nazionali</span>
                    </LinkListItem>
                    <LinkListItem to="/pubblicazioni">
                      <span>Pubblicazioni</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                              <svg className="icon fill-white">
                                  <use href={ sprite2 + "#it-folder" }></use>
                                </svg>Applicazioni
                </DropdownToggle>
                <DropdownMenu role="">
                  <LinkList>
                    <LinkListItem to="/software">
                   <span>Software e Tools</span>
                    </LinkListItem>
                    <LinkListItem to="/tecnologie">
                     <span>Tecnologie</span>
                    </LinkListItem>
                  </LinkList>
                </DropdownMenu>
              </UncontrolledDropdown>
        </Nav>
      </div>
    </Collapse>
  </HeaderContent>
</Header>
  );
}

export default Menu;