import React from "react";
import logo_portale from "../assets/img/logo_portale.png";
import ENEA_logo from "../assets/img/enea.png";
import MITE_logo from "../assets/img/mite.png";
import sprite from "../assets/svg/ponmetroca.svg";
import sprite2 from "../assets/svg/sprite.svg";
import Header from './Header';
import Cookiebar from './Cookiesbar';
import { Link } from 'react-router-dom';


function HeaderTop() {
  return (
    <div className="it-header-wrapper">
      <Cookiebar />
    <div className="it-header-slim-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="it-header-slim-wrapper-content">
              <a className="d-none d-lg-block navbar-brand  pt-3" href="http://www.enea.it" target="_blank" rel="noreferrer">  <img src={ENEA_logo} title="ENEA" alt="ENEA" width="150" height="50"/></a>
              <div className="nav-mobile">
                <nav>
                  <Link className="it-opener d-lg-none" data-toggle="collapse" to="#menu-principale" role="button" aria-expanded="false" aria-controls="menu-principale">
                  <img src={ENEA_logo} title="ENEA" alt="ENEA" width="150" height="50"/>
                    <svg className="icon">
                    <use href={ sprite2 + "#it-expand" }></use>
                    </svg>
                  </Link>

                </nav>
              </div>
              <div className="it-header-slim-right-zone">
                <a className="d-lg-block navbar-brand  pt-3" href="https://www.mite.gov.it" target="_blank" rel="noreferrer">  <img src={MITE_logo} title="MITE" alt="MITE" width="200" height=""/></a>
              </div>
              <div className="it-header-slim-right-zone">
                <div className="it-access-top-wrapper">
                  <a className="btn btn-primary btn-sm d-none" href="http://192.107.92.226/sp/">Accedi</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="it-nav-wrapper">
      <div className="it-header-center-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-header-center-content-wrapper">
                <div className="it-brand-wrapper">
                  <Link to="/#">
                    <img src={logo_portale} title="PNEE" alt="PNEE" width="110" height="100%"/>
                    <div className="it-brand-text">
                      <h2 className="no_toc pl-2">PnPE<sup>2</sup></h2>
                      <h3 className="no_toc d-none d-md-block pl-2">Portale Nazionale sulla Prestazione Energetica degli Edifici</h3>
                      <h3 className="no_toc d-none d-md-block pl-2">Digital One Stop Shop</h3>
                    </div>
                    </Link>
                </div>
                <div className="it-right-zone">
                  <div className="it-socials pt-4 d-none d-md-flex">
                  <ul className="list-inline text-right social">
				<li className="small list-inline-item">Seguici su</li>
				<li className="list-inline-item"><a target="_blank" rel="noreferrer" href="https://twitter.com/eneaofficial" aria-label="Collegamento a sito esterno - Twitter - nuova finestra" title="Seguici su Twitter"><svg className="icon"><use href={ sprite + "#ca-twitter" }></use></svg><span className="hidden">Seguici su Twitter</span></a></li>
				<li className="list-inline-item"><a target="_blank" rel="noreferrer" href="https://www.facebook.com/ENEAPaginaufficiale" aria-label="Collegamento a sito esterno - Facebook - nuova finestra" title="Seguici su Facebook"><svg className="icon"><use href={ sprite + "#ca-facebook" }></use></svg><span className="hidden">Seguici su Facebook</span></a></li>
				<li className="list-inline-item"><a target="_blank" rel="noreferrer" href="https://www.youtube.com/user/ENEANEWS" aria-label="Collegamento a sito esterno - YouTube - nuova finestra" title="Seguici su YouTube"><svg className="icon"><use href={ sprite + "#ca-youtube" }></use></svg><span className="hidden">Seguici su YouTube</span></a></li>

			</ul>
                  </div>
                  <div className="it-search-wrapper d-none">
                    <span className="d-none d-md-block">Cerca</span>
                    <a className="search-link rounded-icon" href="/#" aria-label="Cerca">
                      <svg className="icon">
                      <use href={ sprite + "#ca-search" }></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-header-navbar-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <Header />
            </div>
          </div>
        </div>
      </div>
      <div className="it-header-slim-wrapper bg-azzurro">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 col-9">

            </div>
          </div>
                <div className="row mx-4">
            <div className="col-12 mt-2 mb-2">
                         { // <Link className="btn btn-success bg-giallo p-1" to="/doc/guida.pdf" title="Guida all'uso del portale" target="_blank" rel="noreferrer">Guida all'uso del Portale</Link>
                          <Link className="btn btn-success bg-giallo p-1" to="/mappasito" title="Mappa del sito">Mappa del sito</Link>
                         }
                      </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default HeaderTop;
